module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-550253d79d/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"neev Technologies - Custom made software","short_name":"neev.tech","start_url":"/","background_color":"#ffffff","theme_color":"#db3000","display":"standalone","icon":"src/icon/favicon.png","cache_busting_mode":"none","crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-927bf5a9be/0/cache/gatsby-plugin-offline-npm-6.11.0-5ce4664fd4-b783421914.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icon*"]}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gdpr-cookies-virtual-d278c8f206/0/cache/gatsby-plugin-gdpr-cookies-npm-2.0.9-254a57128f-85c4a86a6e.zip/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-HR4W7X0VYT"},"hotjar":{"hjid":"3387599","hjsv":"6"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-e564e8fe5c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
